export interface Flex {
    microform: {
        createField: (
            type: string,
            args: unknown
        ) => {
            load: (selector: string) => void;
            on: (event: string, cb: (data: unknown) => void) => void;
        };
        createToken: (op: object, cb: CreateTokenCallback) => void;
    };
}

export type CreateTokenCallback = (error: CreateTokenError, token: string) => void;

export type CreateTokenError = {
    reason: string;
    details: { location: string }[];
};
export interface DeviceDataParams {
    isv_deviceDataCollectionUrl?: string;
    isv_requestJwt?: string;
    isv_cardinalReferenceId?: string;
}

export interface CybersourceSettings {
    cardinalOrigin?: string;
    tokenizeEarlyAndUseTokensOnly?: boolean;
    earlyTokenizationExclusions?: string;
    flexFormLink?: string;
}

export class CybersourceGeneralError extends Error {
    protected code?: string;

    constructor() {
        super('Cybersource error');

        this.code = 'CYBERSOURCE_GENERAL_ERROR';
    }
}
