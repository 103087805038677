import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import Script from 'next/script';
import { CartAddressType } from '@wilm/shared-types/cart/AddressTypes';
import type { UpdatePaymentMeta } from '@wilm/shared-types/cart/Payment';
import type { Money } from '@wilm/shared-types/product';
import type { CustomerAddressFields } from '@wilm/shared-types/validation-rules/account/addresses';
import type { CardFields } from '@wilm/shared-types/validation-rules/payment';
import { validateCardFields } from '@wilm/shared-types/validation-rules/payment';
import type { Field, FieldErrors } from '@wilm/shared-types/validation-rules/types';
import Button from 'components/commercetools-ui/atoms/button';
import Typography from 'components/commercetools-ui/atoms/typography';
import AmountToPay from 'components/commercetools-ui/organisms/pay-now/amount-to-pay';
import CreditCardFields from 'components/cybersource/credit-cart-fields';
import AddressForm from 'components/sales-link/organisms/content/customer-info/address-form';
import type { MessageModalProps } from 'components/sales-link/organisms/message-modal';
import MessageModal from 'components/sales-link/organisms/message-modal';
import SimpleAddress from 'components/sales-link/atoms/simple-address';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { getTermsAndConditionsMapped, type TermsAndConditionsObject } from 'helpers/termsAndConditions';
import { useCybersourceContext } from 'providers/cybersource';
import { usePayNowOrderContext } from 'providers/pay-now/order';
import type { TermsAndConditionsType } from '../../checkout/components/terms-and-conditions';
import TermsAndConditions from '../../checkout/components/terms-and-conditions';

interface PaymentFieldsProps {
    termsAndConditions: TermsAndConditionsObject;
}

const PaymentFields: FC<PaymentFieldsProps> = ({ termsAndConditions }) => {
    const { formatMessage: formatOrderMessage } = useFormat({ name: 'order' });
    const { cybersourceSettings } = useCybersourceContext();

    const {
        payNowSettings,
        payNowOrderInfo,
        payment,
        addPaymentToGetTokenContext,
        clearPayNowOrderInfo,
        setShowThankYouPage,
        addressFields,
        setAddressFields,
        cardFields,
        setCardFields
    } = usePayNowOrderContext();
    const { setupMicroform, makeCCPayment } = useCybersourceContext();

    const [paymentLoading, setPaymentLoading] = useState(false);
    console.info('---> cardFields', cardFields);

    const [cardFieldsErrors, setCardFieldsErrors] = useState<FieldErrors>({});

    const [moneyAmount, setMoneyAmount] = useState<Money | undefined>(payNowOrderInfo?.moneyToPay);
    const [moneyAmountError, setMoneyAmountError] = useState<string | undefined>(undefined);
    console.info('---> moneyAmount', moneyAmount);

    const [messageModalData, setMessageModalData] = useState<MessageModalProps['data']>({ message: '', type: 'error' });
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

    const openModalWithInfo = useCallback((modalProps: MessageModalProps['data']) => {
        const {
            type,
            message,
            heading,
            showCloseButton,
            closeButtonText,
            showCTAButton,
            ctaButtonText,
            ctaButtonVariant,
            ctaButtonAction
        } = modalProps;

        setMessageModalData({
            heading: heading ?? 'NOTE',
            message: message,
            type: type,
            showCloseButton: showCloseButton ?? true,
            closeButtonText: closeButtonText ?? 'I understand',
            showCTAButton: showCTAButton,
            ctaButtonText: ctaButtonText,
            ctaButtonVariant: ctaButtonVariant,
            ctaButtonAction: ctaButtonAction
        });
        setIsMessageModalOpen(true);
    }, []);

    const [hasValidBillingAddress, setHasValidBillingAddress] = useState(false);

    const [isTermsAccepted, setIsTermsAccepted] = useState(false);

    const [termsAndConditionsMapped, setTermsAndConditionsMapped] = useState<TermsAndConditionsType>({});

    const [termsError, setTermsError] = useState<string | undefined>(undefined);

    useEffect(() => {
        const country = addressFields.country.value;
        const initialTermsAndConditionsMapped = getTermsAndConditionsMapped(termsAndConditions, country);
        setTermsAndConditionsMapped(initialTermsAndConditionsMapped);
    }, [addressFields.country.value]);

    const createPaymentAndSetupMicroform = useCallback(
        async (moneyAmount: Money) => {
            const payment = await addPaymentToGetTokenContext(moneyAmount);

            if (payment?.signature) {
                setupMicroform(payment.signature);
            }
            return payment;
        },
        [setupMicroform, addPaymentToGetTokenContext]
    );

    const handleTermsChange = useCallback(
        ({ checked, name }: { checked: boolean; name: string }) => {
            setTermsAndConditionsMapped({
                ...termsAndConditionsMapped,
                [name]: {
                    ...termsAndConditionsMapped[name],
                    checked
                }
            });
        },
        [termsAndConditionsMapped, setTermsAndConditionsMapped]
    );

    useEffect(() => {
        setIsTermsAccepted(Object.values(termsAndConditionsMapped).every(term => term.checked));
    }, [termsAndConditionsMapped, setIsTermsAccepted]);

    const handleAddressFieldChange = useCallback(
        (field: Field, value: string | boolean) => {
            if (field.name === 'country') {
                addressFields.region.value = '';
            }
            setAddressFields({
                ...addressFields,
                [field.name]: {
                    ...field,
                    value
                }
            } as CustomerAddressFields);
        },
        [addressFields]
    );

    const handleAddressAndAmountSave = useCallback(() => {
        console.info('---> moneyAmount', moneyAmount);
        console.info('---> addressFields', addressFields);
        if (moneyAmount && moneyAmount?.centAmount === 0) {
            setMoneyAmountError('Amount should be greater than 0');
            return false;
        }
        if (moneyAmount?.centAmount && moneyAmount?.centAmount > (payNowOrderInfo?.moneyToPay.centAmount ?? 0)) {
            setMoneyAmountError(
                'Amount should be less than or equal to the amount left to pay - ' +
                    CurrencyHelpers.formatForCurrency(payNowOrderInfo?.moneyToPay ?? 0)
            );
            return false;
        }
        if (!moneyAmount?.centAmount) {
            setMoneyAmountError('Choose the amount to pay');
            return false;
        }

        setMoneyAmountError(undefined);
        setHasValidBillingAddress(true);

        void createPaymentAndSetupMicroform(moneyAmount);

        return true;
    }, [moneyAmount, addressFields, payNowOrderInfo, createPaymentAndSetupMicroform]);

    const handlePayNowClick = useCallback(async () => {
        setPaymentLoading(true);
        console.info('---> cardFields', cardFields);
        // check isTermsAccepted
        if (!isTermsAccepted) {
            console.info('---> isTermsAccepted', isTermsAccepted);
            setTermsError('Please accept Terms and Conditions to proceed');
            setPaymentLoading(false);
            return;
        }
        setTermsError(undefined);
        const options = {
            expirationMonth: cardFields.expiryMonth.value,
            expirationYear: cardFields.expiryYear.value
        };

        const meta: UpdatePaymentMeta = {
            paymentId: payment!.id,
            orderNumber: payNowOrderInfo!.orderNumber,
            isCheckout: false
        };

        const fieldsErrors = validateCardFields(cardFields);
        console.log('---> fieldsErrors', fieldsErrors);

        if (Object.keys(fieldsErrors).length) {
            setCardFieldsErrors(fieldsErrors);
            setPaymentLoading(false);
            return;
        }

        if (!moneyAmount) {
            openModalWithInfo({
                heading: 'ERROR',
                type: 'error',
                message: 'Sorry something went wrong and need to start from the beginning.',
                ctaButtonVariant: 'warning',
                ctaButtonText: 'I understand',
                ctaButtonAction: clearPayNowOrderInfo,
                showCTAButton: true,
                showCloseButton: false
            });
            setPaymentLoading(false);
            return;
        }

        console.info('---> calling makeCCPayment', options, meta);

        const creditCardPaymentResponse = await makeCCPayment(options, meta);

        console.info('---> creditCardPaymentResponse', creditCardPaymentResponse);

        if (creditCardPaymentResponse.isError && Object.keys(creditCardPaymentResponse.fieldsErrors).length) {
            console.info('---> fieldsErrors', creditCardPaymentResponse.fieldsErrors);
            setCardFieldsErrors(creditCardPaymentResponse.fieldsErrors);
            setPaymentLoading(false);
            return;
        }

        if (creditCardPaymentResponse.isError && creditCardPaymentResponse.needsToStartFromBeginning) {
            openModalWithInfo({
                heading: 'WARNING',
                type: 'error',
                message: 'Your payment session has expired. Add your credit/debit card details and try again.',
                ctaButtonVariant: 'warning',
                ctaButtonText: 'I understand',
                ctaButtonAction() {
                    void createPaymentAndSetupMicroform(moneyAmount);
                    setIsMessageModalOpen(false);
                },
                showCTAButton: true,
                showCloseButton: false
            });
            setPaymentLoading(false);
            return;
        }

        if (creditCardPaymentResponse.isError) {
            openModalWithInfo({
                heading: 'ERROR',
                type: 'error',
                message:
                    'There was an unexpected issue while processing your payment. You will receive a confirmation email if the payment was successfully processed.',
                ctaButtonVariant: 'warning',
                ctaButtonText: 'I understand',
                ctaButtonAction() {
                    clearPayNowOrderInfo();
                    setIsMessageModalOpen(false);
                },
                showCTAButton: true,
                showCloseButton: false
            });
            setPaymentLoading(false);
            return;
        }

        if (!creditCardPaymentResponse.isError) {
            setShowThankYouPage(true);
            clearPayNowOrderInfo();
        }
        setPaymentLoading(false);
    }, [
        payNowOrderInfo,
        cardFields,
        payment,
        makeCCPayment,
        setShowThankYouPage,
        clearPayNowOrderInfo,
        isTermsAccepted,
        createPaymentAndSetupMicroform,
        moneyAmount,
        openModalWithInfo
    ]);

    if (!payNowOrderInfo) {
        return null;
    }

    return (
        <>
            <h2 className="mt-30 text-lg">{formatOrderMessage({ id: 'pay.now.billing.address', defaultMessage: 'Billing Address' })}</h2>
            <div className="max-w-400">
                {hasValidBillingAddress && (
                    <div>
                        <SimpleAddress
                            address={Object.values(addressFields).reduce((acc, field) => {
                                acc[field.name] = field.value;
                                return acc;
                            }, {} as any)}
                            containerClassName="font-bold"
                        />
                        <h2 className="mt-30 text-lg">
                            {formatOrderMessage({ id: 'pay.now.amount.to.pay', defaultMessage: 'Amount to pay' })}
                        </h2>
                        <p>{CurrencyHelpers.formatForCurrency(moneyAmount!)}</p>
                        <Button className="mt-20 w-full" variant="secondary" onClick={() => setHasValidBillingAddress(false)}>
                            {formatOrderMessage({ id: 'change.billing.address', defaultMessage: 'Change Address or Amount' })}
                        </Button>
                    </div>
                )}
                <div
                    className={`mb-30 mt-12 grid gap-12 overflow-hidden rounded-md bg-white transition-[max-height] duration-500 ease-in-out ${!hasValidBillingAddress ? 'max-h-[1200px]' : 'max-h-0'}`}
                >
                    <AddressForm
                        fields={addressFields}
                        addressType={CartAddressType.BILLING}
                        handleFieldChange={handleAddressFieldChange}
                        showCancel={false}
                        saveButtonText="Continue"
                        saveButtonClassName="w-full"
                        onSave={handleAddressAndAmountSave}
                    >
                        <div className="mt-20">
                            <AmountToPay
                                moneyToPay={payNowOrderInfo.moneyToPay}
                                onAmountChange={setMoneyAmount}
                                enablePartialPayment={payNowSettings.enablePartialPayment}
                            />
                            {moneyAmountError && <p className="text-red-500">{moneyAmountError}</p>}
                        </div>
                    </AddressForm>
                </div>

                {/* // CC form */}
                {hasValidBillingAddress && (
                    <div className="max-w-400">
                        <CreditCardFields
                            paymentIsLoading={false}
                            cardFields={cardFields}
                            cardFieldsErrors={cardFieldsErrors}
                            setCardField={(field: keyof CardFields, value: string) => {
                                if (field === 'expiryYear') {
                                    value = '20' + value;
                                }
                                setCardFields(
                                    prev =>
                                        ({
                                            ...prev,
                                            [field]: {
                                                ...prev[field],
                                                value
                                            }
                                        }) as CardFields
                                );
                            }}
                        />
                    </div>
                )}

                {/* Pay button */}
                {hasValidBillingAddress && (
                    <Button className="mt-20 w-full" variant="primary" onClick={handlePayNowClick} loading={paymentLoading}>
                        {formatOrderMessage({ id: 'pay.now.pay', defaultMessage: 'Pay Now' })}
                    </Button>
                )}

                {/* // T&C */}

                {hasValidBillingAddress && termsError && (
                    <Typography className="mt-10 text-sm leading-tight text-input-error" as="p">
                        {termsError}
                    </Typography>
                )}

                {hasValidBillingAddress && termsAndConditionsMapped && (
                    <div className="mt-10">
                        <TermsAndConditions termsAndConditions={termsAndConditionsMapped} handleTermsChange={handleTermsChange} />
                    </div>
                )}
            </div>
            <Script src={cybersourceSettings.flexFormLink} strategy="lazyOnload" />
            <MessageModal data={messageModalData} isOpen={isMessageModalOpen} closeModal={() => {}} />
        </>
    );
};

export default PaymentFields;
