import React from 'react';
import type { FC } from 'react';
import type { Order } from '@wilm/shared-types/cart';
import type { Money } from '@wilm/shared-types/product';
import Skeleton from 'react-loading-skeleton';
import Typography from 'components/commercetools-ui/atoms/typography';
import OrderItemsList from 'components/commercetools-ui/organisms/order-items-listing/order-items-list';
import Price from 'components/sales-link/organisms/content/products/price';
import useClassNames from 'helpers/hooks/useClassNames';

export interface Props {
    title: string;
    order?: Order;
    className?: string;
    asSkeleton?: boolean;
    classNames?: Record<string, string>;
    button?: React.ReactNode;
}

const OrderSummary: FC<Props> = ({ className, classNames, asSkeleton, order, title, button }) => {
    const itemsListClassName = useClassNames([classNames?.itemsList]);
    const lineItems = order?.lineItems;
    const total = order?.totalGross ?? ({ centAmount: 0, currencyCode: 'USD' } as Money);
    const discountedAmount = order?.discountedAmount ?? ({ centAmount: 0, currencyCode: 'USD' } as Money);

    if (asSkeleton || !order) {
        return (
            <div className={className}>
                <div className="border-checkout-border mb-16 py-16 lg:border-b lg:pt-0">
                    <Skeleton className="h-30 w-full" />
                </div>
                <div className="border-checkout-border mb-16 py-16 lg:border-b lg:pt-0">
                    <Skeleton className="h-80 w-full" />
                </div>
                <Skeleton className="h-30" />
            </div>
        );
    }

    return (
        <div className={className}>
            <div className="border-checkout-border py-16 lg:border-b lg:pt-0">
                {title && (
                    <Typography className="text-16 md:text-18" as="h4">
                        {title}
                    </Typography>
                )}
            </div>

            {lineItems && <OrderItemsList lineItems={lineItems} className={itemsListClassName} />}

            <div className="border-checkout-border my-16 border-t pt-24 lg:mb-0">
                <div className="mb-6 flex w-full items-center justify-between text-md capitalize lg:text-base">
                    <span>Subtotal:</span> <Price price={order.subtotal} />
                </div>
                {!!(discountedAmount.centAmount && discountedAmount.centAmount > 0) && (
                    <div className="mb-6 flex w-full items-center justify-between text-md capitalize lg:text-base">
                        <span>Discount:</span>{' '}
                        <span>
                            - <Price price={discountedAmount} />
                        </span>
                    </div>
                )}
                <div className="mb-6 flex w-full items-center justify-between text-md capitalize lg:text-base">
                    <span>Tax:</span> <Price price={order.taxed?.amount ?? 0} />
                </div>
                <div className="flex items-center justify-between pt-12 text-18 md:pb-20">
                    <span className="font-bold">Total:</span>
                    <span className="font-bold">
                        <Price price={total} />
                    </span>
                </div>
            </div>

            {button}
        </div>
    );
};

export default OrderSummary;
