import type { Money } from '../product/Money';

export enum PaymentMethodType {
    CARD = 'card',
    DIRECT_DEBIT = 'directDebit',
    INVOICE = 'invoice',
    INSTALMENT = 'instalment',
    EMPTY = ''
}

export interface UpdatePaymentMeta {
    paymentId?: string;
    orderNumber?: string;
    email?: string;
    isCheckout?: boolean;
    salesLink?: {
        hash: string;
        unlockId: string;
    };
}

export interface PaymentMethodInfo {
    label: string;
    type: PaymentMethodType;
}

export interface PaymentMethod {
    name: string;
    type: PaymentMethodType;
    image: {
        src: string;
    };
}

export type PaymentObject = {
    id: string;
    paymentProvider: string;
    paymentMethod: string | null;
    amountPlanned: Money;
    debug: string;
    paymentStatus: object;
    version: number;
    cardSummary: string;
    signature: string;
    deviceDataParams: {
        isv_requestJwt: string;
        isv_cardinalReferenceId: string;
        isv_deviceDataCollectionUrl: string;
    };
    payerEnrolmentData: {
        isv_payerEnrollHttpCode: number;
        isv_payerEnrollStatus: string;
        isv_payerAuthenticationRequired: boolean;
        isv_stepUpUrl: string;
        isv_responseJwt: string;
    };
    maskedCard: string;
    expiryMonth: string;
    expiryYear: string;
    transactionStatus: string;
};

export interface Payment {
    /**
     * An internal ID to identify this payment
     */
    id: string;

    /**
     * The name of the payment service provider
     */
    paymentProvider: string;

    /**
     * The ID used by the payment service provider for this payment
     */
    paymentId: string;

    /**
     * How much money this payment intends to receive from the customer. The value usually matches the cart or order gross total.
     */
    amountPlanned: Money;

    /**
     * A text describing the current status of the payment
     */
    debug?: string;

    /**
     * One of the `PaymentStatuses` constants
     */
    paymentStatus: string;

    /**
     * Status of the transaction
     */
    transactionStatus?: string;

    version?: number;

    /**
     * The descriptor of the payment method used for this payment
     */
    paymentMethod: string;

    paymentDetails?: [];
    cardSummary: string;
    maskedCard: string;
    paymentInstalmentOption?: string;
}
