import type { FC } from 'react';
import React, { createContext, useMemo, useCallback, useState } from 'react';
import type { SDKResponse } from '@commercetools/frontend-sdk';
import type { CybersourceIframesProps } from '@wilm/shared-frontend/components/cybersource/iframes';
import type { PaymentObject, UpdatePaymentMeta } from '@wilm/shared-types/cart/Payment';
import type { CreateTokenCallback, CreateTokenError, DeviceDataParams, Flex, CybersourceSettings } from '@wilm/shared-types/cybersource';
import { CybersourceGeneralError } from '@wilm/shared-types/cybersource';
import { FieldsValidationError, type FieldErrors } from '@wilm/shared-types/validation-rules/types';
import CybersourceIframes from 'components/cybersource/iframes';
import { sdk } from 'sdk';

interface CybersourceProviderProps {
    cybersourceSettings: CybersourceSettings;
    children: React.ReactNode;
}
declare global {
    interface Window {
        Flex?: any;
    }
}
interface CybersourceContextShape {
    cybersourceSettings: CybersourceSettings;
    earlyTokenizationExclusions: string[];
    getTransientToken: (options: { expirationMonth: string; expirationYear: string }) => Promise<string>;
    isAsianMidCurrency: (currencyCode: string) => boolean;
    makeCCPayment: (
        options: MakeCCPaymentOptions,
        meta: UpdatePaymentMeta,
        transientToken?: string,
        payment?: PaymentObject
    ) => Promise<MakeCCPaymentResult>;
    setupMicroform: (signature: string) => void;
    numberField: { on: (event: string, cb: (data: unknown) => void) => void } | null;
    addPaymentWithToken: (centAmount: number, currencyCode: string, meta: UpdatePaymentMeta) => Promise<PaymentObject>;
    updatePaymentToTriggerAuthFlow: (meta: UpdatePaymentMeta) => Promise<PaymentObject>;
}

type MakeCCPaymentOptions = {
    expirationMonth: string;
    expirationYear: string;
};

export interface MakeCCPaymentResult {
    isError: boolean;
    needsToStartFromBeginning: boolean;
    fieldsErrors: FieldErrors;
    errorMessage?: string;
}

const CybersourceContext = createContext<CybersourceContextShape>({} as CybersourceContextShape);

const CybersourceProvider: FC<CybersourceProviderProps> = ({ cybersourceSettings, children }) => {
    console.info('---> cybersourceSettings', cybersourceSettings);
    const earlyTokenizationExclusions = useMemo(
        () => cybersourceSettings.earlyTokenizationExclusions?.split(',').map(currency => currency.trim().toUpperCase()) ?? [],
        [cybersourceSettings.earlyTokenizationExclusions]
    );
    const isAsianMidCurrency = useCallback(
        (currencyCode: string) => {
            return earlyTokenizationExclusions && earlyTokenizationExclusions.length > 0
                ? earlyTokenizationExclusions.includes(currencyCode)
                : false;
        },
        [earlyTokenizationExclusions]
    );

    const [microform, setMicroform] = useState<Flex['microform'] | null>(null);
    const [numberField, setNumberField] = useState<{ on: (event: string, cb: (data: unknown) => void) => void } | null>(null);
    const [iframesData, setIframesData] = useState<CybersourceIframesProps>({});

    const setupMicroform = useCallback((signature: string) => {
        console.info('---> setupMicroform', signature);

        const myStyles = {
            input: {
                'font-size': '14px',
                'font-family': 'helvetica, tahoma, calibri, sans-serif',
                color: '#555'
            },
            ':focus': { color: 'blue' },
            ':disabled': { cursor: 'not-allowed' },
            '::placeholder': {
                color: '#414141'
            },
            valid: { color: '#3c763d' },
            invalid: { color: '#a94442' }
        };

        if (window.Flex === undefined) {
            console.error('Flex is not defined');
            return;
        }

        const flex = new window.Flex(signature);

        const microform = flex.microform({ styles: myStyles });
        const number = microform.createField('number', { placeholder: 'Card Number', id: 'cs-form-number-container' });
        const securityCode = microform.createField('securityCode', { placeholder: '***' });

        number.load('#number-container');
        securityCode.load('#securityCode-container');
        setMicroform(microform);
        setNumberField(number);
    }, []);

    const createTransientToken = useCallback(
        (options: { expirationMonth: string; expirationYear: string }): Promise<{ error: CreateTokenError; token: string }> => {
            console.info('---> createTransientToken', options);

            return new Promise(resolve => {
                const microformCreateTokenCallback: CreateTokenCallback = (error, token) => {
                    resolve({ error, token });
                };
                console.log('---> call microform?.createToken');

                microform?.createToken(options, microformCreateTokenCallback);
            });
        },
        [microform]
    );

    const getTransientToken = useCallback(
        async (options: { expirationMonth: string; expirationYear: string }): Promise<string> => {
            const result = await createTransientToken(options);

            const { error, token } = result;
            console.log('---> result', result);

            if (error) {
                console.log('---> error', error);

                switch (error.reason) {
                    case 'CREATE_TOKEN_VALIDATION_PARAMS':
                    case 'CREATE_TOKEN_VALIDATION_FIELDS':
                    case 'CREATE_TOKEN_VALIDATION_SERVERSIDE':
                        if (error?.details && Array.isArray(error.details) && error.details.length > 0) {
                            const errors: string[] = [];
                            error.details.forEach(errorDetail => {
                                console.log('---> errorDetail', errorDetail);
                                errors.push(errorDetail.location);
                            });

                            const createTokenFieldErrors = handleMicroformCreateTokenErrors(errors);

                            throw new FieldsValidationError({ errors: createTokenFieldErrors });
                        } else {
                            //reset form and start from the beginning
                            console.error('---> ERROR ' + error.reason + ' need to reset form and start from the beginning');
                            throw new CybersourceGeneralError();
                        }
                    default:
                        //reset form and start from the beginning
                        console.error('---> ERROR ' + error.reason + ' need to reset form and start from the beginning');
                        throw new CybersourceGeneralError();
                }
            } else {
                if (!token) {
                    //reset form and start from the beginning
                    console.error('---> ERROR Token is missing need to reset form and start from the beginning');
                    throw new CybersourceGeneralError();
                }
                return token;
            }
        },
        [createTransientToken]
    );

    const handleMicroformCreateTokenErrors = (errors: string[]) => {
        const fieldsErrors: FieldErrors = {};
        errors.forEach(error => {
            switch (error) {
                case 'number':
                    fieldsErrors.cardNumber = {
                        showError: true,
                        message: 'error.validation.cardNumber'
                    };
                    break;
                case 'securityCode':
                    fieldsErrors.securityCode = {
                        showError: true,
                        message: 'error.validation.securityCode'
                    };
                    break;
                case 'expirationMonth':
                    fieldsErrors.expiryMonth = {
                        showError: true,
                        message: 'error.validation.expiryMonth'
                    };
                    break;
                case 'expirationYear':
                    fieldsErrors.expiryYear = {
                        showError: true,
                        message: 'error.validation.expiryYear'
                    };
                    break;
                default:
                    break;
            }
        });
        return fieldsErrors;
    };

    const updatePaymentWithTransientToken = useCallback(async (token: string, meta: UpdatePaymentMeta): Promise<PaymentObject> => {
        const response = await sdk.callAction({
            actionName: `payment/updatePaymentWithTransientToken`,
            payload: {
                token,
                meta
            }
        });
        const payment = (response.isError ? null : response.data) as PaymentObject;

        return payment;
    }, []);

    const updatePaymentWithClientUserData = useCallback(
        async (meta: UpdatePaymentMeta): Promise<{ payment?: PaymentObject; error: boolean; errorMsg?: string }> => {
            const acceptHeader = '*/*';
            const userAgentHeader = window.navigator.userAgent;
            const response: SDKResponse<PaymentObject> = await sdk.callAction({
                actionName: `payment/updatePaymentWithClientUserData`,
                payload: {
                    acceptHeader,
                    userAgentHeader,
                    meta
                }
            });
            if (!response.isError && response.data) {
                return {
                    payment: response.data,
                    error: false
                };
            }

            return {
                payment: undefined,
                error: true,
                errorMsg: 'Update payment with 3DS transaction ID failed'
            };
        },
        []
    );

    const updatePaymentWithThreeDsTransactionId = useCallback(
        async (transactionId: string, meta: UpdatePaymentMeta): Promise<{ payment?: PaymentObject; error: boolean; errorMsg?: string }> => {
            const response: SDKResponse<{ payment?: PaymentObject; error: boolean; errorMsg?: string }> = await sdk.callAction({
                actionName: `payment/updatePaymentWithThreeDsTransactionId`,
                payload: {
                    transactionId,
                    meta
                }
            });

            if (!response.isError && response.data) {
                return response.data;
            }

            return {
                payment: undefined,
                error: true,
                errorMsg: 'Update payment with 3DS transaction ID failed'
            };
        },
        []
    );

    const addPaymentWithToken = useCallback(async (centAmount: number, currencyCode: string, meta: UpdatePaymentMeta) => {
        const response = await sdk.callAction({
            actionName: `payment/addPaymentWithCustomerToken`,
            payload: {
                currencyCode,
                centAmount,
                meta
            }
        });

        const payment = (response.isError ? null : response.data) as PaymentObject;

        return payment;
    }, []);

    const updatePaymentToTriggerAuthFlow = useCallback(async (meta: UpdatePaymentMeta) => {
        console.info('---> updatePaymentToTriggerAuthFlow', meta);

        const response = await sdk.callAction({
            actionName: `payment/updatePaymentWithAccountCardData`,
            payload: {
                meta
            }
        });

        const payment = (response.isError ? null : response.data) as PaymentObject;
        return payment;
    }, []);

    const waitForNextMessage = (predicate: (event: MessageEvent) => boolean, timeout: number) => {
        return new Promise((resolve, reject) => {
            function messageHandler(event: MessageEvent) {
                // Ensure the message is from the expected origin
                console.log('---> messageHandler', event);

                if (predicate(event)) {
                    resolve(event.data); // Resolve the promise with the message data
                    window.removeEventListener('message', messageHandler); // Cleanup
                }
            }

            window.addEventListener('message', messageHandler, false);

            // Optional: Set a timeout to reject the promise if the message doesn't arrive
            setTimeout(() => {
                reject(new Error('Timeout waiting for message'));
                window.removeEventListener('message', messageHandler); // Cleanup
            }, timeout);
        });
    };

    const addPaymentTransaction = useCallback(async (meta: UpdatePaymentMeta): Promise<PaymentObject> => {
        const paymentResponse = await sdk.callAction<{ payment: PaymentObject }>({
            actionName: 'payment/addPaymentTransaction',
            payload: {
                meta
            }
        });

        if (paymentResponse.isError || !paymentResponse.data?.payment) {
            throw new CybersourceGeneralError();
        }

        return paymentResponse.data.payment;
    }, []);

    const submitDataCollectionForm = useCallback(
        async (deviceData: DeviceDataParams): Promise<true> => {
            setIframesData(prevState => ({
                ...prevState,
                deviceDataCollectionUrl: deviceData.isv_deviceDataCollectionUrl,
                requestJwt: deviceData.isv_requestJwt
            }));
            // step 6 submit device data
            const cardinalCollectionForm = document.querySelector<HTMLFormElement>('#cardinal_collection_form');

            if (!cardinalCollectionForm) {
                console.error('---> Cardinal form is missing');
                throw new CybersourceGeneralError();
            }
            cardinalCollectionForm.action = deviceData.isv_deviceDataCollectionUrl!;
            cardinalCollectionForm.querySelector<HTMLInputElement>('#cardinal_collection_form_input')!.value = deviceData.isv_requestJwt!;
            cardinalCollectionForm.submit();

            // step 7 Wait for the successful response from the deviceDataCollection
            try {
                const response = await waitForNextMessage(
                    (event: MessageEvent) => event.origin === cybersourceSettings.cardinalOrigin,
                    20000
                );

                console.log('---> response', response);
            } catch (error) {
                console.error('---> Error', error);
                throw new CybersourceGeneralError();
            }

            return true;
        },
        [cybersourceSettings.cardinalOrigin]
    );

    const processPaymentEnrolmentData = useCallback(
        async (payment: PaymentObject, meta: UpdatePaymentMeta): Promise<MakeCCPaymentResult> => {
            const paymentEnrolmentData = payment.payerEnrolmentData;
            if (paymentEnrolmentData.isv_payerEnrollHttpCode === 201 && paymentEnrolmentData.isv_payerEnrollStatus === 'AUTHORIZED') {
                console.log('Direct Authorized NO Step up');
                //successful payment
                return {
                    isError: false,
                    needsToStartFromBeginning: false,
                    fieldsErrors: {},
                    errorMessage: ''
                };
            }
            if (paymentEnrolmentData.isv_payerEnrollHttpCode === 201 && paymentEnrolmentData.isv_payerEnrollStatus === 'DECLINED') {
                console.error('card declined');
                return {
                    isError: true,
                    needsToStartFromBeginning: true,
                    fieldsErrors: {},
                    errorMessage: 'Your card was declined please try again or use another card'
                };
            }
            if (paymentEnrolmentData.isv_payerEnrollHttpCode === 400) {
                console.error('Payment can not be processed');
                return {
                    isError: true,
                    needsToStartFromBeginning: true,
                    fieldsErrors: {},
                    errorMessage: 'Payment can not be processed'
                };
            }
            if (paymentEnrolmentData.isv_payerAuthenticationRequired === true) {
                return await handlePayerAuthentication(paymentEnrolmentData, meta);
            }
            return {
                isError: true,
                needsToStartFromBeginning: false,
                fieldsErrors: {},
                errorMessage: 'Unexpected error'
            };
        },
        []
    );

    const handlePayerAuthentication = async (paymentEnrolmentData: PaymentObject['payerEnrolmentData'], meta: UpdatePaymentMeta) => {
        console.log('Need step up');

        setIframesData(prevState => ({
            ...prevState,
            showSubmitStepUpIframe: true,
            stepUpUrl: paymentEnrolmentData.isv_stepUpUrl,
            responseJwt: paymentEnrolmentData.isv_responseJwt
        }));

        console.log('---> iframesData', iframesData);

        const stepUpForm = document.querySelector<HTMLFormElement>('#step-up-form');
        console.log('---> stepUpForm', stepUpForm);

        if (!stepUpForm) {
            console.error('Step up form is missing');
            return {
                isError: true,
                needsToStartFromBeginning: false,
                fieldsErrors: {},
                errorMessage: 'Step up form is missing'
            };
        }

        stepUpForm.action = paymentEnrolmentData.isv_stepUpUrl!;
        stepUpForm.querySelector<HTMLInputElement>('#step-up-form-input')!.value = paymentEnrolmentData.isv_responseJwt!;

        stepUpForm.submit();

        let response = '';
        try {
            console.log('---> call waitForNextMessage');
            response = (await waitForNextMessage(
                (event: MessageEvent) => event.origin === window.location.origin && typeof event.data === 'string',
                15 * 60 * 1000 // timeout 15 min
            )) as string;

            console.log('---> response', response);
        } catch (error) {
            console.error('---> Error', error);
            return {
                isError: true,
                needsToStartFromBeginning: false,
                fieldsErrors: {},
                errorMessage: 'Could not process payment enrolment data'
            };
        }

        const pspResponse = JSON.parse(response);

        if (!pspResponse?.transactionId) {
            console.error('Transaction ID is missing');
            return {
                isError: true,
                needsToStartFromBeginning: false,
                fieldsErrors: {},
                errorMessage: 'Transaction ID is missing'
            };
        }

        const paymentUpdate = await updatePaymentWithThreeDsTransactionId(pspResponse.transactionId, meta);

        console.log('---> paymentUpdate', paymentUpdate);

        if (paymentUpdate.error && paymentUpdate.errorMsg === 'DuplicatedPayments') {
            console.error('DuplicatedPayments');
            return {
                isError: true,
                needsToStartFromBeginning: false,
                fieldsErrors: {},
                errorMessage: 'DuplicatedPayments'
            };
        }

        if (paymentUpdate.error) {
            return {
                isError: true,
                needsToStartFromBeginning: false,
                fieldsErrors: {},
                errorMessage: 'Unexpected error: ' + paymentUpdate.errorMsg
            };
        }

        if (!paymentUpdate.payment) {
            console.error('Could not process payment enrolment data');
            return {
                isError: true,
                needsToStartFromBeginning: false,
                fieldsErrors: {},
                errorMessage: 'Could not process payment enrolment data'
            };
        }

        setIframesData(prevState => ({
            ...prevState,
            showSubmitStepUpIframe: false
        }));

        return await processPaymentEnrolmentData(paymentUpdate.payment, meta);
    };

    const ensurePaymentWithClientUserData = useCallback(
        async (
            deviceData: DeviceDataParams,
            meta: UpdatePaymentMeta
        ): Promise<{ payment?: PaymentObject; error: boolean; errorMsg?: string }> => {
            // step 7 initialize the enrolment check by updating the payment

            const paymentWithClientUserData = await updatePaymentWithClientUserData(meta);

            if (paymentWithClientUserData.error) {
                return paymentWithClientUserData;
            }

            if (
                // step 9 check this and repeat from step 6
                paymentWithClientUserData?.payment?.payerEnrolmentData?.isv_payerEnrollHttpCode === 201 &&
                paymentWithClientUserData?.payment.payerEnrolmentData?.isv_payerEnrollStatus === 'CUSTOMER_AUTHENTICATION_REQUIRED'
            ) {
                console.log('repeat from step 6');
                await submitDataCollectionForm(deviceData);
                return await ensurePaymentWithClientUserData(deviceData, meta);
            }

            return paymentWithClientUserData;
        },
        [updatePaymentWithClientUserData, submitDataCollectionForm]
    );

    // Helper function to create error responses
    const createError = (needsToStartFromBeginning: boolean, fieldsErrors = {}, errorMessage?: string): MakeCCPaymentResult => ({
        isError: true,
        needsToStartFromBeginning,
        fieldsErrors,
        errorMessage
    });

    const makeCCPayment = useCallback(
        async (
            options: MakeCCPaymentOptions,
            meta: UpdatePaymentMeta,
            transientToken?: string,
            payment?: PaymentObject
        ): Promise<MakeCCPaymentResult> => {
            const successResult: MakeCCPaymentResult = {
                isError: false,
                needsToStartFromBeginning: false,
                fieldsErrors: {}
            };

            // Step 1: Get transient token if not provided
            try {
                if (!transientToken && !payment) {
                    transientToken = await getTransientToken(options);
                }
            } catch (error) {
                if (error instanceof FieldsValidationError) {
                    return createError(false, error.errors);
                }
                return createError(true);
            }

            // Step 2: Update payment with transient token
            try {
                if (!payment && transientToken) {
                    payment = await updatePaymentWithTransientToken(transientToken, meta);
                }
            } catch {
                return createError(true);
            }

            // Step 3: Validate payment and device data
            const isValidPayment =
                payment?.paymentMethod &&
                payment?.deviceDataParams?.isv_requestJwt &&
                payment?.deviceDataParams?.isv_deviceDataCollectionUrl &&
                payment?.deviceDataParams?.isv_cardinalReferenceId;

            if (!isValidPayment) {
                console.log('---> Missing payment or Device data request parameters not received');
                return createError(true);
            }

            const deviceData = payment?.deviceDataParams as DeviceDataParams;

            // Step 4: Submit data collection and process payment
            try {
                console.info('---> submitDataCollectionForm deviceData', deviceData);
                await submitDataCollectionForm(deviceData);
            } catch (error) {
                console.error('submitDataCollectionForm error:', error);
                return createError(true);
            }

            try {
                const paymentWithClientUserData = await ensurePaymentWithClientUserData(deviceData, meta);
                if (paymentWithClientUserData.error) {
                    return createError(false, {}, paymentWithClientUserData.errorMsg);
                }

                const payment = paymentWithClientUserData.payment!;

                const processedEnrolmentData = await processPaymentEnrolmentData(payment, meta);
                if (processedEnrolmentData.isError) {
                    return processedEnrolmentData;
                }

                const paymentAfterTransaction = await addPaymentTransaction(meta);
                console.info('---> paymentAfterTransaction', paymentAfterTransaction);

                if (paymentAfterTransaction.transactionStatus.toLowerCase() !== 'success') {
                    return createError(false, {}, 'Payment transaction failed');
                }

                return successResult;
            } catch (error) {
                console.error('Payment processing error:', error);
                return createError(false);
            }
        },
        [
            getTransientToken,
            updatePaymentWithTransientToken,
            addPaymentTransaction,
            submitDataCollectionForm,
            ensurePaymentWithClientUserData,
            processPaymentEnrolmentData
        ]
    );

    const value = useMemo(
        () => ({
            cybersourceSettings,
            earlyTokenizationExclusions,
            getTransientToken,
            isAsianMidCurrency,
            makeCCPayment,
            setupMicroform,
            numberField,
            addPaymentWithToken,
            updatePaymentToTriggerAuthFlow
        }),
        [
            cybersourceSettings,
            earlyTokenizationExclusions,
            getTransientToken,
            isAsianMidCurrency,
            makeCCPayment,
            setupMicroform,
            numberField,
            addPaymentWithToken,
            updatePaymentToTriggerAuthFlow
        ]
    );

    return (
        <CybersourceContext.Provider value={value}>
            <CybersourceIframes
                showSubmitStepUpIframe={iframesData?.showSubmitStepUpIframe}
                deviceDataCollectionUrl={iframesData?.deviceDataCollectionUrl}
                requestJwt={iframesData?.requestJwt}
                stepUpUrl={iframesData?.stepUpUrl}
                responseJwt={iframesData?.responseJwt}
            />
            {children}
        </CybersourceContext.Provider>
    );
};

export default CybersourceProvider;

export function useCybersourceContext() {
    const context = React.useContext(CybersourceContext);

    if (!context) {
        throw new Error('CybersourceContext must be used within the scope of CybersourceContextProvider');
    }

    return context;
}
